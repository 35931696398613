<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="#7CB9E8" top :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn :ripple="false" text @click="showSnackBar = false"> Ok </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs10 sm10 text-center pt-6 pb-6>
        <span style="font-family: sedanRegular; color: #000000; font-size: 20px"
          >UPDATE ASSET
        </span>
      </v-flex>
    </v-layout>
    <!-- <v-layout wrap justify-center v-if="pendingList.length > 0">
      <v-flex xs12 sm10 text-center pb-6>
        <v-layout wrap pb-5>
          <span
            style="font-family: sedanRegular; color: #000000; font-size: 18px"
            >Incomplete Asset
          </span>
        </v-layout>
        <v-card outlined>
          <v-layout wrap justify-start py-2>
            <v-flex xs2 sm1 pa-2 v-for="(item, i) in pendingList" :key="i">
              <v-chip
                outlined
                color="#464746"
                v-if="item.assetID"
                @click="getView(item._id)"
                style="font-family: sedanRegular; color: #000; cursor: pointer"
                >{{ item.assetID }}</v-chip
              >
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout> -->
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-layout wrap justify-center>
        <v-flex xs10 sm10 md10 text-left>
          <span
            style="font-family: sedanRegular; color: #000000; font-size: 18px"
            >ASSET DETAILS
          </span>
          <v-layout wrap>
            <v-flex xs12 pt-4>
              <v-card>
                <v-layout wrap justify-start py-2 pb-5 pa-5>
                  <v-flex xs12 sm6 md6 pa-2>
                    <span
                      style="
                        font-family: sedanRegular;
                        color: #000000;
                        font-size: 15px;
                      "
                      >Asset ID</span
                    >
                    <v-text-field
                      outlined
                      dense
                      ref="id"
                      disabled
                      :rules="rules"
                      v-model="assetDetails.assetID"
                      placeholder="Unique reference code"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6 pa-2>
                    <span
                      style="
                        font-family: sedanRegular;
                        color: #000000;
                        font-size: 15px;
                      "
                      >Asset Name</span
                    >
                    <v-text-field
                      :rules="rules"
                      outlined
                      disabled
                      v-model="assetDetails.assetName"
                      dense
                      placeholder="Short name for the asset"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12 pa-2>
                    <span
                      style="
                        font-family: sedanRegular;
                        color: #000000;
                        font-size: 15px;
                      "
                      >Asset-Description</span
                    >
                    <!-- <v-textarea
                      :rules="rules"
                      outlined
                      dense
                      v-model="assetDetails.description"
                      placeholder="Brief description of the asset that can be viewed by the public"
                    ></v-textarea> -->
                    <div id="app">
                      <vue-editor
                        v-model="assetDetails.description"
                      ></vue-editor>
                    </div>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-center>
        <v-flex xs10 sm10 md10 text-left pt-10>
          <span
            style="font-family: sedanRegular; color: #000000; font-size: 18px"
            >LAND DETAILS
          </span>
          <v-layout wrap>
            <v-flex xs12 pt-4>
              <v-card>
                <v-layout wrap justify-start py-2 pb-5 pa-5>
                  <v-flex xs12 sm6 md6 pa-2>
                    <span
                      style="
                        font-family: sedanRegular;
                        color: #000000;
                        font-size: 15px;
                      "
                      >Address</span
                    >
                    <v-text-field
                      :hide-details="true"
                      outlined
                      v-model="assetDetails.address"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6 pa-2>
                    <span
                      style="
                        font-family: sedanRegular;
                        color: #000000;
                        font-size: 15px;
                      "
                      >City</span
                    >
                    <!-- <v-select :hide-details="true" v-model="city" outlined dense></v-select> -->
                    <v-text-field
                      :hide-details="true"
                      outlined
                      v-model="assetDetails.city"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6 pa-2>
                    <span
                      style="
                        font-family: sedanRegular;
                        color: #000000;
                        font-size: 15px;
                      "
                      >State</span
                    >
                    <!-- <v-select :hide-details="true" v-model="state" outlined dense></v-select> -->
                    <v-text-field
                      :hide-details="true"
                      outlined
                      v-model="assetDetails.state"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6 pa-2>
                    <span
                      style="
                        font-family: sedanRegular;
                        color: #000000;
                        font-size: 15px;
                      "
                      >Postcode</span
                    >
                    <v-text-field
                      :hide-details="true"
                      outlined
                      dense
                      type="number"
                      v-model="assetDetails.postCode"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 pa-2 py-4>
                    <div style="border-bottom: 1px solid #000"></div>
                  </v-flex>
                  <v-flex xs12 sm6 md6 pa-2>
                    <span
                      style="
                        font-family: sedanRegular;
                        color: #000000;
                        font-size: 15px;
                      "
                      >Title number</span
                    >
                    <v-text-field
                      :hide-details="true"
                      outlined
                      type="number"
                      dense
                      v-model="assetDetails.titleNumber"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6 pa-2>
                    <span
                      style="
                        font-family: sedanRegular;
                        color: #000000;
                        font-size: 15px;
                      "
                      >Survey number</span
                    >
                    <v-text-field
                      :hide-details="true"
                      outlined
                      type="number"
                      dense
                      v-model="assetDetails.surveyNumber"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6 pa-2>
                    <span
                      style="
                        font-family: sedanRegular;
                        color: #000000;
                        font-size: 15px;
                      "
                      >Sub division number</span
                    >
                    <v-text-field
                      :hide-details="true"
                      outlined
                      dense
                      type="number"
                      v-model="assetDetails.subDivisionNumber"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 pa-2 py-4>
                    <div style="border-bottom: 1px solid #000"></div>
                  </v-flex>
                  <v-flex xs12 sm6 md6 pa-2>
                    <span
                      style="
                        font-family: sedanRegular;
                        color: #000000;
                        font-size: 15px;
                      "
                      >Plot-size (sq.m)</span
                    >
                    <v-text-field
                      :hide-details="true"
                      outlined
                      dense
                      v-model="assetDetails.plotSize"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6 pa-2>
                    <span
                      style="
                        font-family: sedanRegular;
                        color: #000000;
                        font-size: 15px;
                      "
                      >Land-value</span
                    >
                    <v-text-field
                      :hide-details="true"
                      outlined
                      dense
                      v-model="assetDetails.landValue"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-center>
        <v-flex xs10 sm10 md10 text-left pt-16>
          <span
            style="font-family: sedanRegular; color: #000000; font-size: 18px"
            >LOCATION
          </span>
          <v-layout wrap>
            <v-flex xs12 pt-4>
              <v-card>
                <v-layout wrap justify-start py-6 pa-5>
                  <v-flex xs12 sm12>
                    <span
                      style="
                        font-family: sedanRegular;
                        color: #000000;
                        font-size: 15px;
                      "
                      >GPS-location-codes (Coordinates are in decimal
                      system)</span
                    >
                  </v-flex>
                  <v-flex xs11>
                    <v-layout
                      wrap
                      v-for="(item, i) in gpsLocationCodes"
                      :key="i"
                    >
                      <v-flex xs5 sm5 md6 pa-2 v-if="item">
                        <span
                          style="
                            font-family: sedanRegular;
                            color: #000000;
                            font-size: 15px;
                          "
                          >Latitude</span
                        >
                        <v-text-field
                          outlined
                          dense
                          :rules="rulesLat"
                          v-model="item.latitude"
                        ></v-text-field>
                      </v-flex>
                      <v-flex
                        xs5
                        sm5
                        :md5="gpsLocationCodes.length > 1"
                        :md6="gpsLocationCodes[0]"
                        :lg5="gpsLocationCodes.length > 1"
                        :lg6="gpsLocationCodes[0]"
                        pa-2
                        v-if="item"
                      >
                        <span
                          style="
                            font-family: sedanRegular;
                            color: #000000;
                            font-size: 15px;
                          "
                          >Longitude</span
                        >
                        <v-text-field
                          outlined
                          dense
                          :rules="rulesLon"
                          v-model="item.longitude"
                        ></v-text-field>
                      </v-flex>
                      <v-flex
                        xs1
                        text-center
                        v-if="gpsLocationCodes.length > 1"
                        pt-10
                      >
                        <v-icon @click="gpsLocationCodes.splice(i, 1)"
                          >mdi-close</v-icon
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs1 pt-10 text-left>
                    <v-icon @click="AddField">mdi-plus-circle-outline</v-icon>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-end py-5>
        <v-flex xs12 sm4 md4>
          <v-btn
            rounded
            color="#7CB9E8"
            dark
            :disabled="!valid"
            @click="validate()"
          >
            <span style="font-size: 12px; font-family: montserratSemiBold"
              >Save and Continue</span
            >
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
export default {
  props: ["asset"],
  components: {
    VueEditor,
  },
  data: () => ({
    showSnackBar: false,
    msg: null,
    timeout: 5000,
    assetDetails: {
      assetID: "",
      // assetType: "Land",
      assetName: "",
      description: "",
      address: "",
      city: "",
      state: "",
      postCode: "",
      titleNumber: "",
      surveyNumber: "",
      subDivisionNumber: "",
      plotSize: "",
      landValue: "",
    },
    users: "",
    valid: true,
    id: "",
    assetId: "",
    submittedLevels: [],
    gpsLocationCodes: [{ latitude: "", longitude: "" }],
    assetArray: ["Land"],
    rules: [(v) => !!v || "Required"],
    rulesLat: [
      (v) => !!v || "Required",
      (v) =>
        /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/.test(v) ||
        "Latitude is not valid",
      (v) => (isFinite(v) && Math.abs(v) <= 90) || "Latitude is not valid",
    ],
    rulesLon: [
      (v) => !!v || "Required",
      (v) =>
        /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/.test(v) ||
        "Longitude is not valid",
      (v) => (isFinite(v) && Math.abs(v) <= 180) || "Longitude is not valid",
    ],
    pendingList: [],
  }),
  beforeMount() {
    // this.getPending();
    this.getView();
    this.assetDetails = this.asset;
    this.$emit("stepper", {
      ref: "page1",
      assetDetails: this.assetDetails,
      gpsLocationCodes: this.gpsLocationCodes,
    });
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.saveData();
      } else window.scrollTo(0, 0);
    },
    AddField: function () {
      this.gpsLocationCodes.push({ latitude: "", longitude: "" });
    },
    saveData() {
      //   if (this.assetId) {
      //     this.assetDetails.asset_id = this.assetId;
      //   } else {
      //     this.assetDetails.asset_id = this.id;
      //   }
      //   this.assetDetails.gpsLocationCodes = this.gpsLocationCodes;
      //   axios({
      //     method: "POST",
      //     url: "/asset/register/level1",
      //     data: this.assetDetails,
      //     headers: {
      //       token: localStorage.getItem("token"),
      //     },
      //   })
      //     .then((response) => {
      //       this.appLoading = false;
      //       if (response.data.status) {
      //         this.id = response.data.data._id;
      // console.log("iddddd", this.id);
      // this.msg = response.data.msg;
      // this.showSnackBar = true;
      this.$emit("stepper", {
        ref: "page1Tab",
        level: "tab-2",
        subLevel: 1,
        gpsLocationCodes: this.gpsLocationCodes,
      });
      this.$emit("stepper", {
        ref: "assetGet",
        assetIdNew: this.id,
      });
      //   } else {
      //     this.msg = response.data.msg;
      //     this.showSnackBar = true;
      //   }
      // })
      // .catch((err) => {
      //   this.ServerError = true;
      //   console.log(err);
      // });
    },
    // getPending() {
    //   axios({
    //     method: "GET",
    //     url: "/user/own/assets/list",
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //     params: {
    //       status: "Incomplete",
    //       assetType: "Land",
    //     },
    //   })
    //     .then((response) => {
    //       if (response.data.status) {
    //         this.pendingList = response.data.data;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    getView() {
      axios({
        method: "GET",
        url: "/asset/latest/transaction/view/" + this.$route.query.id,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.assetDetails = response.data.data;
            this.users = response.data.accessibleUsers;
            this.$emit("stepper", {
              ref: "managementPage",
              management: this.assetDetails,
              assetIdNew: this.$route.query.id,
              species: this.assetDetails,
              files: this.assetDetails,
              users: this.users,
            });
            this.assetId = this.$route.query.id;
            this.submittedLevels = this.assetDetails.submittedLevels;
            this.$emit("stepper", {
              ref: "page1SubTab",
              subLevel: this.submittedLevels,
            });
            // var allArray = [1, 2, 3];
            // var newArray = allArray.filter(
            //   (x) => !this.submittedLevels.includes(x)
            // );
            // if (newArray.length > 0) {
            //   if (newArray[0] == 1) {
            //     this.$emit("stepper", {
            //       ref: "page1Tab",
            //       level: "tab-1",
            //     });
            //   } else if (newArray[0] == 2) {
            //     this.$emit("stepper", {
            //       ref: "page1Tab",
            //       level: "tab-2",
            //     });
            //   } else if (newArray[0] == 3) {
            //     this.$emit("stepper", {
            //       ref: "page1Tab",
            //       level: "tab-3",
            //     });
            //   }
            // } else {
            //   this.$emit("stepper", {
            //     ref: "page1Tab",
            //     level: "tab-4",
            //     check: newArray,
            //   });
            // }
            var newlocation = [];
            var getLocation = response.data.data.gpsLocationCodes;
            for (let j = 0; j < getLocation.length; j++) {
              newlocation.push({
                latitude: getLocation[j].location[1],
                longitude: getLocation[j].location[0],
              });
              this.gpsLocationCodes = newlocation;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
