<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="#7CB9E8" top :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex
          text-left
          class="align-self-center"
          style="font-size: 18px; font-family: montserratSemiBold"
          >{{ msg }}</v-flex
        >
        <v-flex text-right>
          <v-btn :ripple="false" text @click="showSnackBar = false">
            <span style="font-size: 18px; font-family: montserratExtraBold"
              >Ok
            </span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs10 sm8 text-center pt-6 pb-6>
        <span style="font-family: sedanRegular; color: #000000; font-size: 20px"
          >UPDATE ASSET
        </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center px-10>
      <v-flex xs10 sm2 md2 text-center>
        <span
          style="
            font-family: montserratSemiBold;
            color: #000000;
            font-size: 15px;
          "
          >{{ asset.assetID }}
        </span>
      </v-flex>
      <v-flex xs10 sm10 md10 text-left>
        <span
          style="
            font-family: montserratSemiBold;
            color: #000000;
            font-size: 15px;
          "
          >{{ asset.assetName }}
        </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs10 sm10 md10 text-left pt-3>
        <span style="font-family: sedanRegular; color: #000000; font-size: 18px"
          >SPECIES
        </span>
        <v-layout wrap>
          <v-flex xs12 pt-4>
            <v-card>
              <v-layout wrap justify-start py-6 pa-5>
                <v-flex xs12 sm6>
                  <v-layout wrap>
                    <v-flex
                      xs11
                      sm11
                      md11
                      pa-1
                      v-for="(list, l) in speciesFlora"
                      :key="l"
                    >
                      <span
                        v-if="list"
                        style="
                          font-family: sedanRegular;
                          color: #000000;
                          font-size: 15px;
                        "
                        >Flora</span
                      >
                      <v-layout wrap>
                        <v-flex
                          xs11
                          :md11="speciesFlora.length > 1"
                          :md12="speciesFlora[0]"
                          :lg11="speciesFlora.length > 1"
                          :lg12="speciesFlora[0]"
                        >
                          <v-text-field
                            :hide-details="true"
                            outlined
                            dense
                            autofocus
                            v-model="list.speciesFlora"
                          ></v-text-field>
                        </v-flex>
                        <v-flex
                          xs1
                          text-center
                          v-if="speciesFlora.length > 1"
                          pt-2
                        >
                          <v-icon @click="speciesFlora.splice(l, 1)"
                            >mdi-close</v-icon
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs1 pt-10 text-left>
                      <v-icon @click="speciesAddF()"
                        >mdi-plus-circle-outline</v-icon
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-layout wrap>
                    <v-flex
                      xs11
                      sm11
                      md11
                      pa-1
                      v-for="(list1, e) in speciesFauna"
                      :key="e"
                    >
                      <span
                        v-if="list1"
                        style="
                          font-family: sedanRegular;
                          color: #000000;
                          font-size: 15px;
                        "
                        >Fauna</span
                      >
                      <v-layout wrap>
                        <v-flex
                          xs11
                          :md11="speciesFauna.length > 1"
                          :md12="speciesFauna[0]"
                          :lg11="speciesFauna.length > 1"
                          :lg12="speciesFauna[0]"
                        >
                          <v-text-field
                            :hide-details="true"
                            outlined
                            autofocus
                            v-model="list1.speciesFauna"
                            dense
                          ></v-text-field>
                        </v-flex>
                        <v-flex
                          xs1
                          text-center
                          v-if="speciesFauna.length > 1"
                          pt-2
                        >
                          <v-icon @click="speciesFauna.splice(e, 1)"
                            >mdi-close</v-icon
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs1 pt-10 text-left>
                      <v-icon @click="speciesAddA()"
                        >mdi-plus-circle-outline</v-icon
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-layout wrap>
                    <v-flex
                      xs11
                      sm11
                      md11
                      pa-1
                      v-for="(item, i) in speciesOther"
                      :key="i"
                    >
                      <span
                        v-if="item"
                        style="
                          font-family: sedanRegular;
                          color: #000000;
                          font-size: 15px;
                        "
                        >Other</span
                      >
                      <v-layout wrap>
                        <v-flex
                          xs11
                          :md11="speciesOther.length > 1"
                          :md12="speciesOther[0]"
                          :lg11="speciesOther.length > 1"
                          :lg12="speciesOther[0]"
                        >
                          <v-text-field
                            :hide-details="true"
                            outlined
                            autofocus
                            v-model="item.speciesOther"
                            dense
                          ></v-text-field>
                        </v-flex>
                        <v-flex
                          xs1
                          text-center
                          v-if="speciesOther.length > 1"
                          pt-2
                        >
                          <v-icon @click="speciesOther.splice(i, 1)"
                            >mdi-close</v-icon
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs1 pt-10 text-left>
                      <v-icon @click="otherAdd()"
                        >mdi-plus-circle-outline</v-icon
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm12 md12 pa-2>
                  <span
                    style="
                      font-family: sedanRegular;
                      color: #000000;
                      font-size: 15px;
                    "
                    >Additional comments</span
                  >
                  <v-textarea
                    :hide-details="true"
                    outlined
                    rows="3"
                    dense
                    v-model="speciesAdditionalComments"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-end py-5>
      <v-flex xs12 sm4 md4>
        <v-btn rounded color="#7CB9E8" dark @click="saveData()">
          <span style="font-size: 12px; font-family: montserratSemiBold"
            >Save and Continue</span
          >
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import axios from "axios";
export default {
  props: ["asset", "assetIdNew", "species"],
  data: () => ({
    showSnackBar: false,
    msg: null,
    timeout: 5000,
    speciesFlora: [{ speciesFlora: "" }],
    speciesFauna: [{ speciesFauna: "" }],
    speciesOther: [{ speciesOther: "" }],
    speciesAdditionalComments: "",
    assetId: "",
    submittedLevels: [],
    pendingList: [],
  }),
  beforeMount() {
    this.$emit("stepper", {
      ref: "page3",
      speciesFlora: this.speciesFlora,
      speciesFauna: this.speciesFauna,
      speciesOther: this.speciesOther,
      speciesAdditionalComments: this.speciesAdditionalComments,
    });
    this.assetId = this.assetIdNew;
    if (this.species) {
      this.speciesAdditionalComments = this.species.speciesAdditionalComments;
      var newFlo = [];
      var getFlo = this.species.speciesFlora;
      for (let j = 0; j < getFlo.length; j++) {
        newFlo.push({
          speciesFlora: getFlo[j],
        });
        this.speciesFlora = newFlo;
      }
      var newFau = [];
      var getFau = this.species.speciesFauna;
      for (let l = 0; l < getFau.length; l++) {
        newFau.push({
          speciesFauna: getFau[l],
        });
        this.speciesFauna = newFau;
      }
      var newOther = [];
      var getOther = this.species.speciesOther;
      for (let k = 0; k < getOther.length; k++) {
        newOther.push({
          speciesOther: getOther[k],
        });
        this.speciesOther = newOther;
      }
    }
  },
  methods: {
    AddField: function () {
      this.location.push({ latitude: "", longitude: "" });
    },
    speciesAddF: function () {
      this.speciesFlora.push({ speciesFlora: "" });
    },
    speciesAddA: function () {
      this.speciesFauna.push({ speciesFauna: "" });
    },
    otherAdd() {
      this.speciesOther.push({ speciesOther: "" });
    },
    saveData() {
      //   var data = {};
      //   this.speciesFlora = this.speciesFlora.map((x) => x.speciesFlora);
      //   data["speciesFlora"] = this.speciesFlora;
      //   this.speciesFauna = this.speciesFauna.map((x) => x.speciesFauna);
      //   data["speciesFauna"] = this.speciesFauna;
      //   this.speciesOther = this.speciesOther.map((x) => x.speciesOther);
      //   data["speciesOther"] = this.speciesOther;
      //   data["speciesAdditionalComments"] = this.speciesAdditionalComments;
      //   if (this.assetId) {
      //     data["asset_id"] = this.assetId;
      //   }
      //   axios({
      //     method: "POST",
      //     url: "/asset/register/level3",
      //     data: data,
      //     headers: {
      //       token: localStorage.getItem("token"),
      //     },
      //   })
      //     .then((response) => {
      //       this.appLoading = false;
      //       if (response.data.status) {
      //         this.species = response.data.data;
      //         var newFlo = [];
      //         var getFlo = this.species.speciesFlora;
      //         for (let j = 0; j < getFlo.length; j++) {
      //           newFlo.push({
      //             speciesFlora: getFlo[j],
      //           });
      //           this.speciesFlora = newFlo;
      //         }
      //         var newFau = [];
      //         var getFau = this.species.speciesFauna;
      //         for (let l = 0; l < getFau.length; l++) {
      //           newFau.push({
      //             speciesFauna: getFau[l],
      //           });
      //           this.speciesFauna = newFau;
      //         }
      //         var newOther = [];
      //         var getOther = this.species.speciesOther;
      //         for (let k = 0; k < getOther.length; k++) {
      //           newOther.push({
      //             speciesOther: getOther[k],
      //           });
      //           this.speciesOther = newOther;
      //         }
      //         this.msg = response.data.msg;
      //         this.showSnackBar = true;
      this.$emit("stepper", {
        ref: "page3Tab",
        level: "tab-4",
        subLevel: 3,
        speciesFlora: this.speciesFlora,
        speciesFauna: this.speciesFauna,
        speciesOther: this.speciesOther,
        speciesAdditionalComments: this.speciesAdditionalComments,
      });
      //   } else {
      //     this.msg = response.data.msg;
      //     this.showSnackBar = true;
      //   }
      // })
      // .catch((err) => {
      //   this.ServerError = true;
      //   console.log(err);
      // });
    },
  },
};
</script>
