<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="#7CB9E8" top :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn :ripple="false" text @click="showSnackBar = false"> Ok </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs10 sm10 md8 text-center pt-6 pb-6>
        <span style="font-family: sedanRegular; color: #000000; font-size: 20px"
          >UPDATE ASSET
        </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center px-10>
      <v-flex xs10 sm2 md2 text-center>
        <span style="font-family: montserratSemiBold; color: #000000; font-size: 15px"
          >{{ asset.assetID }}
        </span>
      </v-flex>
      <v-flex xs10 sm10 md10 text-left>
        <span style="font-family: montserratSemiBold; color: #000000; font-size: 15px"
          >{{ asset.assetName }}
        </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs10 sm10 md10 text-left pt-3>
        <span style="font-family: sedanRegular; color: #000000; font-size: 18px"
          >PROJECT DETAILS
        </span>
        <v-layout wrap>
          <v-flex xs12 pt-4>
            <v-card>
              <v-layout wrap justify-start py-2 pb-5 pa-5>
                <v-flex xs12 sm6 md6 pa-2>
                  <span
                    style="
                      font-family: sedanRegular;
                      color: #000000;
                      font-size: 15px;
                    "
                    >Project Start Date</span
                  >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        v-model="management.projectStartDate"
                        append-icon="mdi-calendar"
                        readonly
                        :hide-details="true"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="management.projectStartDate"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 sm6 md6 pa-2>
                  <span
                    style="
                      font-family: sedanRegular;
                      color: #000000;
                      font-size: 15px;
                    "
                    >Project End Date</span
                  >
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        v-model="management.projectEndDate"
                        append-icon="mdi-calendar"
                        readonly
                        :hide-details="true"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="management.projectEndDate"
                      @input="menu1 = false"
                      :min="management.projectStartDate"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 sm6 md6 pa-2>
                  <span
                    style="
                      font-family: sedanRegular;
                      color: #000000;
                      font-size: 15px;
                    "
                    >Project manager</span
                  >
                  <v-text-field
                    :hide-details="true"
                    outlined
                    v-model="management.projectManager"
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs10 sm10 md10 text-left pt-16>
        <span style="font-family: sedanRegular; color: #000000; font-size: 18px"
          >OWNER DETAILS
        </span>
        <v-layout wrap>
          <v-flex xs12 pt-4>
            <v-card>
              <v-layout wrap justify-start py-2 pb-5 pa-5>
                <v-flex xs12 sm6 md6 pa-2>
                  <span
                    style="
                      font-family: sedanRegular;
                      color: #000000;
                      font-size: 15px;
                    "
                    >Owner Name</span
                  >
                  <v-text-field
                    :hide-details="true"
                    outlined
                    dense
                    v-model="management.ownerName"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6 pa-2>
                  <span
                    style="
                      font-family: sedanRegular;
                      color: #000000;
                      font-size: 15px;
                    "
                    >Owner Business Registration number
                  </span>
                  <v-text-field
                    :hide-details="true"
                    outlined
                    type="number"
                    v-model="management.ownerBusinessRegistrationNumber"
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6 pa-2>
                  <span
                    style="
                      font-family: sedanRegular;
                      color: #000000;
                      font-size: 15px;
                    "
                    >Owner Individual ID (government issued)</span
                  >
                  <v-text-field
                    :hide-details="true"
                    outlined
                    dense
                    type="number"
                    v-model="management.ownerIndividualID"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs10 sm10 md10 text-left pt-10>
        <span style="font-family: sedanRegular; color: #000000; font-size: 18px"
          >LEASE / OWNERSHIP
        </span>
        <v-layout wrap>
          <v-flex xs12 pt-4>
            <v-card>
              <v-layout wrap justify-start py-2 pb-5 pa-5>
                <v-flex xs12 sm6 md6 pa-2>
                  <span
                    style="
                      font-family: sedanRegular;
                      color: #000000;
                      font-size: 15px;
                    "
                    >Owned or Leased (O/L)</span
                  >
                  <v-text-field
                    :hide-details="true"
                    outlined
                    v-model="management.ownedOrLeased"
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6 pa-2>
                  <span
                    style="
                      font-family: sedanRegular;
                      color: #000000;
                      font-size: 15px;
                    "
                    >Lease monthly amount</span
                  >
                  <v-text-field
                    :hide-details="true"
                    outlined
                    v-model="management.leaseMonthlyAmount"
                    dense
                    type="number"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6 pa-2>
                  <span
                    style="
                      font-family: sedanRegular;
                      color: #000000;
                      font-size: 15px;
                    "
                    >Lease term start</span
                  >
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        v-model="management.leaseTermStart"
                        append-icon="mdi-calendar"
                        readonly
                        :hide-details="true"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="management.leaseTermStart"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 sm6 md6 pa-2>
                  <span
                    style="
                      font-family: sedanRegular;
                      color: #000000;
                      font-size: 15px;
                    "
                    >Lease term end</span
                  >
                  <v-menu
                    ref="menu3"
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        v-model="management.leaseTermEnd"
                        append-icon="mdi-calendar"
                        readonly
                        :hide-details="true"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="management.leaseTermEnd"
                      @input="menu3 = false"
                      :min="management.leaseTermStart"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 sm12 md12 pa-2>
                  <span
                    style="
                      font-family: sedanRegular;
                      color: #000000;
                      font-size: 15px;
                    "
                    >Lease comments</span
                  >
                  <v-textarea
                    :hide-details="true"
                    outlined
                    v-model="management.leaseComments"
                    rows="3"
                    dense
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs10 sm10 md10 text-left pt-10>
        <span style="font-family: sedanRegular; color: #000000; font-size: 18px"
          >OWNER BANK ACCOUNT DETAILS (PRIVATE)
        </span>
        <v-layout wrap>
          <v-flex xs12 pt-4>
            <v-card>
              <v-layout wrap justify-start py-2 pb-5 pa-5>
                <v-flex xs12 sm6 md6 pa-2>
                  <span
                    style="
                      font-family: sedanRegular;
                      color: #000000;
                      font-size: 15px;
                    "
                    >Owner Bank Name</span
                  >
                  <v-text-field
                    :hide-details="true"
                    outlined
                    v-model="management.ownerBankName"
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6 pa-2>
                  <span
                    style="
                      font-family: sedanRegular;
                      color: #000000;
                      font-size: 15px;
                    "
                    >Owner BankBranch</span
                  >
                  <v-text-field
                    :hide-details="true"
                    outlined
                    v-model="management.ownerBankBranch"
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6 pa-2>
                  <span
                    style="
                      font-family: sedanRegular;
                      color: #000000;
                      font-size: 15px;
                    "
                    >Owner Bank Account Number</span
                  >
                  <v-text-field
                    :hide-details="true"
                    outlined
                    dense
                    v-model="management.ownerBankAccountNumber"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-end py-5>
      <v-flex xs12 sm4 md4>
        <v-btn rounded color="#7CB9E8" dark @click="saveData()">
          <span style="font-size: 12px; font-family: montserratSemiBold"
            >Save and Continue</span
          >
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import axios from "axios";
export default {
  props: ["asset", "manage", "assetIdNew"],
  data: () => ({
    showSnackBar: false,
    msg: null,
    timeout: 5000,
    management: {
      projectStartDate: "",
      projectEndDate: "",
      projectManager: "",
      ownerName: "",
      ownerBusinessRegistrationNumber: "",
      ownerIndividualID: "",
      ownedOrLeased: "",
      leaseMonthlyAmount: "",
      leaseTermStart: "",
      leaseTermEnd: "",
      leaseComments: "",
      ownerBankName: "",
      ownerBankBranch: "",
      ownerBankAccountNumber: "",
    },
    id: "",
    assetId: "",
    submittedLevels: [],
    pendingList: [],
    menu: false,
    menu1: false,
    menu2: false,
    menu3: false,
  }),
  beforeMount() {
    this.$emit("stepper", {
      ref: "page2",
      management: this.management,
    });
    this.management = this.manage;
    this.assetId = this.assetIdNew;
    console.log("assetid", this.assetId);
    if (this.manage.projectStartDate) {
      this.management.projectStartDate = this.management.projectStartDate.slice(
        0,
        10
      );
      this.management.projectEndDate = this.management.projectEndDate.slice(
        0,
        10
      );
      this.management.leaseTermStart = this.management.leaseTermStart.slice(
        0,
        10
      );
      this.management.leaseTermEnd = this.management.leaseTermEnd.slice(0, 10);
    }
    // this.management.projectStartDate= this.formatDate(this.management.projectStartDate)
  },
  methods: {
    saveData() {
      //   if (this.assetId) {
      //     this.management.asset_id = this.assetId;
      //   }
      //   axios({
      //     method: "POST",
      //     url: "/asset/register/level2",
      //     data: this.management,
      //     headers: {
      //       token: localStorage.getItem("token"),
      //     },
      //   })
      //     .then((response) => {
      //       this.appLoading = false;
      //       if (response.data.status) {
      //         this.msg = response.data.msg;
      //         this.id = response.data.data._id;
      //         this.showSnackBar = true;
      this.$emit("stepper", {
        ref: "page2Tab",
        level: "tab-3",
        subLevel: 2,
      });
      this.$emit("stepper", {
        ref: "assetGetPage2",
        assetIdNew: this.id,
      });
      //   } else {
      //     this.msg = response.data.msg;
      //     this.showSnackBar = true;
      //   }
      // })
      // .catch((err) => {
      //   this.ServerError = true;
      //   console.log(err);
      // });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();

      var strTime = day + " " + dt.slice(4, 7) + " " + year;

      return strTime;
    },
  },
};
</script>
